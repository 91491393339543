import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 5×2\\@90% 1RM`}</p>
    <p>{`Pendlay Rows 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 2 Lap Run`}</p>
    <p>{`50-S2OH (95/65)(RX+ 115/75)`}</p>
    <p>{`50-Burpees Over Bar`}</p>
    <p>{`Cash Out: 2 Lap Run`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`partition the s2oh and burpees however you’d like in between the
runs.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      